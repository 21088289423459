<template>
    <div class="nec-navbar" :class="{ 'open': toggle }">
        <ul v-if="cItems">
            <li v-for="item in cItems" :key="item.id">
                <router-link v-if="!item.outerLink" :to="item.url"
                    :class="{ disabled: item.disabled, 'router-link-active': item._active }" :title="item.linkTitle"
                    :target="item.blank ? '_blank' : null">
                    <svg v-if="item.title == 'Home'" xmlns="http://www.w3.org/2000/svg" width="19" height="20"
                        viewBox="0 0 19 20" fill="none">
                        <path
                            d="M7.20341 17.8813V15.0044C7.20341 14.27 7.80312 13.6747 8.54289 13.6747H11.2471C11.6024 13.6747 11.9431 13.8148 12.1943 14.0641C12.4455 14.3135 12.5866 14.6517 12.5866 15.0044V17.8813C12.5844 18.1866 12.705 18.4802 12.9216 18.6969C13.1383 18.9136 13.4332 19.0354 13.7407 19.0354H15.5857C16.4473 19.0377 17.2745 18.6994 17.8846 18.0953C18.4946 17.4913 18.8375 16.671 18.8375 15.8156V7.61972C18.8375 6.92875 18.529 6.27332 17.9951 5.83001L11.7189 0.853924C10.6271 -0.0185536 9.06289 0.00961648 8.00373 0.920829L1.87077 5.83001C1.31164 6.26025 0.977452 6.91762 0.960938 7.61972V15.8073C0.960938 17.5901 2.41683 19.0354 4.21275 19.0354H6.01557C6.65437 19.0355 7.17351 18.5238 7.17814 17.8897L7.20341 17.8813Z"
                            fill="#666666" />
                    </svg>
                    <svg v-if="item.title == 'Wallet'" xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                        viewBox="0 0 21 19" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M16.6693 5.51535H20.9004C20.9004 2.11814 18.8648 0.133545 15.4159 0.133545H6.38484C2.93595 0.133545 0.900391 2.11814 0.900391 5.47201V12.7951C0.900391 16.149 2.93595 18.1335 6.38484 18.1335H15.4159C18.8648 18.1335 20.9004 16.149 20.9004 12.7951V12.4831H16.6693C14.7056 12.4831 13.1137 10.9311 13.1137 9.01655C13.1137 7.10203 14.7056 5.55001 16.6693 5.55001V5.51535ZM16.6693 7.00596H20.1537C20.5661 7.00596 20.9004 7.33188 20.9004 7.73393V10.2645C20.8956 10.6646 20.5641 10.9878 20.1537 10.9925H16.7493C15.7552 11.0055 14.8859 10.3419 14.6604 9.39787C14.5475 8.81184 14.706 8.20711 15.0935 7.74576C15.4809 7.28441 16.0577 7.01362 16.6693 7.00596ZM16.8204 9.66652H17.1493C17.5715 9.66652 17.9137 9.33284 17.9137 8.92122C17.9137 8.5096 17.5715 8.17591 17.1493 8.17591H16.8204C16.6185 8.1736 16.424 8.25018 16.2804 8.38858C16.1368 8.52698 16.0559 8.71567 16.0559 8.91255C16.0559 9.3256 16.3968 9.66178 16.8204 9.66652ZM5.63817 5.51535H11.2826C11.7048 5.51535 12.0471 5.18166 12.0471 4.77004C12.0471 4.35842 11.7048 4.02473 11.2826 4.02473H5.63817C5.21942 4.02471 4.87859 4.35314 4.87372 4.76137C4.8737 5.17442 5.21454 5.5106 5.63817 5.51535Z"
                            fill="#666666" />
                    </svg>
                    <svg v-if="item.title == 'Staking'" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                        viewBox="0 0 20 20" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M5.73848 0.59082H14.657C17.9049 0.59082 19.7385 2.43298 19.748 5.68087V14.6004C19.748 17.8473 17.9049 19.6904 14.657 19.6904H5.73848C2.49059 19.6904 0.648438 17.8473 0.648438 14.6004V5.68087C0.648438 2.43298 2.49059 0.59082 5.73848 0.59082ZM10.2457 15.7368C10.6573 15.7368 11.0001 15.4312 11.0383 15.0206V5.28935C11.0765 4.9933 10.9342 4.6963 10.6764 4.53491C10.408 4.37257 10.0833 4.37257 9.82644 4.53491C9.56764 4.6963 9.42535 4.9933 9.45304 5.28935V15.0206C9.50175 15.4312 9.84459 15.7368 10.2457 15.7368ZM14.6384 15.7369C15.0395 15.7369 15.3823 15.4313 15.431 15.0206V11.8883C15.4587 11.5818 15.3164 11.2962 15.0576 11.1339C14.8007 10.9715 14.4761 10.9715 14.2087 11.1339C13.9499 11.2962 13.8076 11.5818 13.8458 11.8883V15.0206C13.884 15.4313 14.2268 15.7369 14.6384 15.7369ZM6.58783 15.021C6.54963 15.4317 6.20679 15.7372 5.79519 15.7372C5.38455 15.7372 5.04076 15.4317 5.00352 15.021V8.4221C4.97487 8.1251 5.11716 7.83001 5.37596 7.66766C5.63285 7.50532 5.9585 7.50532 6.21634 7.66766C6.47323 7.83001 6.61743 8.1251 6.58783 8.4221V15.021Z"
                            fill="#666666" />
                    </svg>
                    <svg v-if="item.title == 'Governance'" xmlns="http://www.w3.org/2000/svg" width="21" height="21"
                        viewBox="0 0 21 21" fill="none">
                        <path
                            d="M1.04583 7.26029C0.865377 6.92558 0.781776 6.5472 0.804409 6.16763C0.827042 5.78805 0.955012 5.42229 1.17396 5.1114C1.53176 4.58642 2.01094 4.1555 2.57082 3.85523L8.85251 0.585835C9.46993 0.264784 10.1556 0.097168 10.8515 0.097168C11.5474 0.097168 12.2331 0.264784 12.8505 0.585835L19.1313 3.85774C19.6912 4.15802 20.1704 4.58893 20.5282 5.11391C20.7471 5.4248 20.8751 5.79056 20.8977 6.17014C20.9204 6.54972 20.8368 6.92809 20.6563 7.2628C20.462 7.63029 20.1707 7.93745 19.8139 8.15082C19.4572 8.36419 19.0487 8.47559 18.633 8.47291H3.06994C2.65386 8.47551 2.24506 8.3638 1.88812 8.14996C1.53119 7.93613 1.23983 7.62838 1.04583 7.26029ZM20.0634 18.5223C20.0634 18.0781 19.8869 17.652 19.5728 17.3379C19.2587 17.0238 18.8327 16.8474 18.3885 16.8474V10.1478H16.7136V16.8474H14.2013V10.1478H12.5264V16.8474H9.1766V10.1478H7.50171V16.8474H4.98937V10.1478H3.31447V16.8474C2.87026 16.8474 2.44425 17.0238 2.13014 17.3379C1.81604 17.652 1.63958 18.0781 1.63958 18.5223C1.41747 18.5223 1.20447 18.6105 1.04741 18.7676C0.890362 18.9246 0.802131 19.1376 0.802131 19.3597C0.802131 19.5818 0.890362 19.7948 1.04741 19.9519C1.20447 20.1089 1.41747 20.1972 1.63958 20.1972H20.0634C20.2855 20.1972 20.4985 20.1089 20.6556 19.9519C20.8126 19.7948 20.9009 19.5818 20.9009 19.3597C20.9009 19.1376 20.8126 18.9246 20.6556 18.7676C20.4985 18.6105 20.2855 18.5223 20.0634 18.5223Z"
                            fill="#666666" />
                    </svg>
                    <svg v-if="item.title == 'Settings'" xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                        viewBox="0 0 21 19" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M16.6693 5.51535H20.9004C20.9004 2.11814 18.8648 0.133545 15.4159 0.133545H6.38484C2.93595 0.133545 0.900391 2.11814 0.900391 5.47201V12.7951C0.900391 16.149 2.93595 18.1335 6.38484 18.1335H15.4159C18.8648 18.1335 20.9004 16.149 20.9004 12.7951V12.4831H16.6693C14.7056 12.4831 13.1137 10.9311 13.1137 9.01655C13.1137 7.10203 14.7056 5.55001 16.6693 5.55001V5.51535ZM16.6693 7.00596H20.1537C20.5661 7.00596 20.9004 7.33188 20.9004 7.73393V10.2645C20.8956 10.6646 20.5641 10.9878 20.1537 10.9925H16.7493C15.7552 11.0055 14.8859 10.3419 14.6604 9.39787C14.5475 8.81184 14.706 8.20711 15.0935 7.74576C15.4809 7.28441 16.0577 7.01362 16.6693 7.00596ZM16.8204 9.66652H17.1493C17.5715 9.66652 17.9137 9.33284 17.9137 8.92122C17.9137 8.5096 17.5715 8.17591 17.1493 8.17591H16.8204C16.6185 8.1736 16.424 8.25018 16.2804 8.38858C16.1368 8.52698 16.0559 8.71567 16.0559 8.91255C16.0559 9.3256 16.3968 9.66178 16.8204 9.66652ZM5.63817 5.51535H11.2826C11.7048 5.51535 12.0471 5.18166 12.0471 4.77004C12.0471 4.35842 11.7048 4.02473 11.2826 4.02473H5.63817C5.21942 4.02471 4.87859 4.35314 4.87372 4.76137C4.8737 5.17442 5.21454 5.5106 5.63817 5.51535Z"
                            fill="#666666" />
                    </svg>
                    <span class="title">{{ item.title }}</span>
                </router-link>
                <a v-else :href="item.url" :class="{ disabled: item.disabled, 'router-link-active': item._active }"
                    :title="item.linkTitle" :target="item.blank ? '_blank' : null">
                    <svg v-if="item.title == 'Home'" xmlns="http://www.w3.org/2000/svg" width="19" height="20"
                        viewBox="0 0 19 20" fill="none">
                        <path
                            d="M7.20341 17.8813V15.0044C7.20341 14.27 7.80312 13.6747 8.54289 13.6747H11.2471C11.6024 13.6747 11.9431 13.8148 12.1943 14.0641C12.4455 14.3135 12.5866 14.6517 12.5866 15.0044V17.8813C12.5844 18.1866 12.705 18.4802 12.9216 18.6969C13.1383 18.9136 13.4332 19.0354 13.7407 19.0354H15.5857C16.4473 19.0377 17.2745 18.6994 17.8846 18.0953C18.4946 17.4913 18.8375 16.671 18.8375 15.8156V7.61972C18.8375 6.92875 18.529 6.27332 17.9951 5.83001L11.7189 0.853924C10.6271 -0.0185536 9.06289 0.00961648 8.00373 0.920829L1.87077 5.83001C1.31164 6.26025 0.977452 6.91762 0.960938 7.61972V15.8073C0.960938 17.5901 2.41683 19.0354 4.21275 19.0354H6.01557C6.65437 19.0355 7.17351 18.5238 7.17814 17.8897L7.20341 17.8813Z"
                            fill="#666666" />
                    </svg>
                    <svg v-if="item.title == 'Wallet'" xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                        viewBox="0 0 21 19" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M16.6693 5.51535H20.9004C20.9004 2.11814 18.8648 0.133545 15.4159 0.133545H6.38484C2.93595 0.133545 0.900391 2.11814 0.900391 5.47201V12.7951C0.900391 16.149 2.93595 18.1335 6.38484 18.1335H15.4159C18.8648 18.1335 20.9004 16.149 20.9004 12.7951V12.4831H16.6693C14.7056 12.4831 13.1137 10.9311 13.1137 9.01655C13.1137 7.10203 14.7056 5.55001 16.6693 5.55001V5.51535ZM16.6693 7.00596H20.1537C20.5661 7.00596 20.9004 7.33188 20.9004 7.73393V10.2645C20.8956 10.6646 20.5641 10.9878 20.1537 10.9925H16.7493C15.7552 11.0055 14.8859 10.3419 14.6604 9.39787C14.5475 8.81184 14.706 8.20711 15.0935 7.74576C15.4809 7.28441 16.0577 7.01362 16.6693 7.00596ZM16.8204 9.66652H17.1493C17.5715 9.66652 17.9137 9.33284 17.9137 8.92122C17.9137 8.5096 17.5715 8.17591 17.1493 8.17591H16.8204C16.6185 8.1736 16.424 8.25018 16.2804 8.38858C16.1368 8.52698 16.0559 8.71567 16.0559 8.91255C16.0559 9.3256 16.3968 9.66178 16.8204 9.66652ZM5.63817 5.51535H11.2826C11.7048 5.51535 12.0471 5.18166 12.0471 4.77004C12.0471 4.35842 11.7048 4.02473 11.2826 4.02473H5.63817C5.21942 4.02471 4.87859 4.35314 4.87372 4.76137C4.8737 5.17442 5.21454 5.5106 5.63817 5.51535Z"
                            fill="#666666" />
                    </svg>
                    <svg v-if="item.title == 'Staking'" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                        viewBox="0 0 20 20" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M5.73848 0.59082H14.657C17.9049 0.59082 19.7385 2.43298 19.748 5.68087V14.6004C19.748 17.8473 17.9049 19.6904 14.657 19.6904H5.73848C2.49059 19.6904 0.648438 17.8473 0.648438 14.6004V5.68087C0.648438 2.43298 2.49059 0.59082 5.73848 0.59082ZM10.2457 15.7368C10.6573 15.7368 11.0001 15.4312 11.0383 15.0206V5.28935C11.0765 4.9933 10.9342 4.6963 10.6764 4.53491C10.408 4.37257 10.0833 4.37257 9.82644 4.53491C9.56764 4.6963 9.42535 4.9933 9.45304 5.28935V15.0206C9.50175 15.4312 9.84459 15.7368 10.2457 15.7368ZM14.6384 15.7369C15.0395 15.7369 15.3823 15.4313 15.431 15.0206V11.8883C15.4587 11.5818 15.3164 11.2962 15.0576 11.1339C14.8007 10.9715 14.4761 10.9715 14.2087 11.1339C13.9499 11.2962 13.8076 11.5818 13.8458 11.8883V15.0206C13.884 15.4313 14.2268 15.7369 14.6384 15.7369ZM6.58783 15.021C6.54963 15.4317 6.20679 15.7372 5.79519 15.7372C5.38455 15.7372 5.04076 15.4317 5.00352 15.021V8.4221C4.97487 8.1251 5.11716 7.83001 5.37596 7.66766C5.63285 7.50532 5.9585 7.50532 6.21634 7.66766C6.47323 7.83001 6.61743 8.1251 6.58783 8.4221V15.021Z"
                            fill="#666666" />
                    </svg>
                    <svg v-if="item.title == 'Governance'" xmlns="http://www.w3.org/2000/svg" width="21" height="21"
                        viewBox="0 0 21 21" fill="none">
                        <path
                            d="M1.04583 7.26029C0.865377 6.92558 0.781776 6.5472 0.804409 6.16763C0.827042 5.78805 0.955012 5.42229 1.17396 5.1114C1.53176 4.58642 2.01094 4.1555 2.57082 3.85523L8.85251 0.585835C9.46993 0.264784 10.1556 0.097168 10.8515 0.097168C11.5474 0.097168 12.2331 0.264784 12.8505 0.585835L19.1313 3.85774C19.6912 4.15802 20.1704 4.58893 20.5282 5.11391C20.7471 5.4248 20.8751 5.79056 20.8977 6.17014C20.9204 6.54972 20.8368 6.92809 20.6563 7.2628C20.462 7.63029 20.1707 7.93745 19.8139 8.15082C19.4572 8.36419 19.0487 8.47559 18.633 8.47291H3.06994C2.65386 8.47551 2.24506 8.3638 1.88812 8.14996C1.53119 7.93613 1.23983 7.62838 1.04583 7.26029ZM20.0634 18.5223C20.0634 18.0781 19.8869 17.652 19.5728 17.3379C19.2587 17.0238 18.8327 16.8474 18.3885 16.8474V10.1478H16.7136V16.8474H14.2013V10.1478H12.5264V16.8474H9.1766V10.1478H7.50171V16.8474H4.98937V10.1478H3.31447V16.8474C2.87026 16.8474 2.44425 17.0238 2.13014 17.3379C1.81604 17.652 1.63958 18.0781 1.63958 18.5223C1.41747 18.5223 1.20447 18.6105 1.04741 18.7676C0.890362 18.9246 0.802131 19.1376 0.802131 19.3597C0.802131 19.5818 0.890362 19.7948 1.04741 19.9519C1.20447 20.1089 1.41747 20.1972 1.63958 20.1972H20.0634C20.2855 20.1972 20.4985 20.1089 20.6556 19.9519C20.8126 19.7948 20.9009 19.5818 20.9009 19.3597C20.9009 19.1376 20.8126 18.9246 20.6556 18.7676C20.4985 18.6105 20.2855 18.5223 20.0634 18.5223Z"
                            fill="#666666" />
                    </svg>
                    <svg v-if="item.title == 'Settings'" xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                        viewBox="0 0 21 19" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M16.6693 5.51535H20.9004C20.9004 2.11814 18.8648 0.133545 15.4159 0.133545H6.38484C2.93595 0.133545 0.900391 2.11814 0.900391 5.47201V12.7951C0.900391 16.149 2.93595 18.1335 6.38484 18.1335H15.4159C18.8648 18.1335 20.9004 16.149 20.9004 12.7951V12.4831H16.6693C14.7056 12.4831 13.1137 10.9311 13.1137 9.01655C13.1137 7.10203 14.7056 5.55001 16.6693 5.55001V5.51535ZM16.6693 7.00596H20.1537C20.5661 7.00596 20.9004 7.33188 20.9004 7.73393V10.2645C20.8956 10.6646 20.5641 10.9878 20.1537 10.9925H16.7493C15.7552 11.0055 14.8859 10.3419 14.6604 9.39787C14.5475 8.81184 14.706 8.20711 15.0935 7.74576C15.4809 7.28441 16.0577 7.01362 16.6693 7.00596ZM16.8204 9.66652H17.1493C17.5715 9.66652 17.9137 9.33284 17.9137 8.92122C17.9137 8.5096 17.5715 8.17591 17.1493 8.17591H16.8204C16.6185 8.1736 16.424 8.25018 16.2804 8.38858C16.1368 8.52698 16.0559 8.71567 16.0559 8.91255C16.0559 9.3256 16.3968 9.66178 16.8204 9.66652ZM5.63817 5.51535H11.2826C11.7048 5.51535 12.0471 5.18166 12.0471 4.77004C12.0471 4.35842 11.7048 4.02473 11.2826 4.02473H5.63817C5.21942 4.02471 4.87859 4.35314 4.87372 4.76137C4.8737 5.17442 5.21454 5.5106 5.63817 5.51535Z"
                            fill="#666666" />
                    </svg>
                    <span class="title">{{ item.title }}</span>
                </a>
            </li>
        </ul>
    </div>
</template>
<script>
import { helpersMixin } from '../../mixins/helpers.js';
import { getAppNodeParents } from '@/app-structure.js';
import { cloneObject } from '@/utils';

export default {
    mixins: [helpersMixin],

    props: {
        /**
         * Array of navigation links.
         *
         * @type {{title: string, url: string}[]}
         */
        items: {
            type: Array,
            default() {
                return [];
            },
        },
        toggle:{
            type: Boolean
        },
        /** Default icon size */
        iconSize: {
            type: String,
            default: '32',
        },
        /** Render <nav> element. */
        renderNav: {
            type: Boolean,
            default: true,
        },
        /** Render navigation as column. */
        vertical: {
            type: Boolean,
            default: false,
        },
        /** Highlight route by application structure. */
        highlightRouteByAppStructure: {
            type: Boolean,
            default: false,
        },
        ariaLabel: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            dItems: [],
            homeurl: '',
        };
    },

    computed: {
        cItems() {
            this.setIds(this.dItems);
            console.log(this.dItems);
            return this.dItems;
        },

        navTag() {
            return this.renderNav ? 'nav' : 'div';
        },

        routeNames() {
            return this.dItems.map((_item) => _item.url.name);
        },
    },

    watch: {
        /**
         * Watches for vue route change.
         */
        $route(_route) {
            this.processRoute(_route);
        },
    },

    mounted() {
        this.dItems = this.items;
        this.processRoute(this.$route);
    },

    methods: {
        /**
         * @param {object} _route
         */
        processRoute(_route) {
            if (!this.highlightRouteByAppStructure) {
                return;
            }

            const { routeNames } = this;
            const appNode = getAppNodeParents(_route.name).find((_item) => routeNames.includes(_item.route));

            if (appNode) {
                const items = cloneObject(this.items);

                items.forEach((_item) => {
                    _item._active = _item.url.name === appNode.route;
                });

                this.dItems = items;
            }
        },
    },

};
</script>
<style lang="scss">
@import 'style';
</style>
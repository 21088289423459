<template>
    <ul class="f-social-media-links no-markers">
        
    </ul>
</template>

<script>
export default {
    name: 'SocialMediaLinks',
};
</script>

<style lang="scss">
@import 'style';
</style>

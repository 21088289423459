var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"synths-positions-list-dt"},[_c('f-data-table',{staticClass:"f-data-table-body-bg-color",attrs:{"columns":_vm.columns,"items":_vm.items,"action-on-row":_vm.actionOnRow,"first-m-v-column-width":"6","f-card-off":"","caption":"Synths Positions"},scopedSlots:_vm._u([{key:"column-asset",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[_c('f-crypto-symbol',{attrs:{"token":item}})],1)]):[_c('f-crypto-symbol',{attrs:{"token":item}})]]}},{key:"column-amount",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[_c('f-token-value',{attrs:{"value":_vm.formatDebt(item),"token":item,"use-placeholder":false,"max-decimals":_vm.MAX_TOKEN_DECIMALS_IN_TABLES,"no-currency":""}})],1)]):[_c('f-token-value',{attrs:{"value":_vm.formatDebt(item),"token":item,"use-placeholder":false,"max-decimals":_vm.MAX_TOKEN_DECIMALS_IN_TABLES,"no-currency":""}})]]}},{key:"column-amount_nusd",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[_c('f-token-value',{attrs:{"value":_vm.formatDebtNUSD(item),"token":{ symbol: 'NUSD' },"use-placeholder":false,"max-decimals":_vm.MAX_TOKEN_DECIMALS_IN_TABLES,"no-currency":""}})],1)]):[_c('f-token-value',{attrs:{"value":_vm.formatDebtNUSD(item),"token":{ symbol: 'NUSD' },"use-placeholder":false,"max-decimals":_vm.MAX_TOKEN_DECIMALS_IN_TABLES,"no-currency":""}})]]}},{key:"column-actions",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[(item.canMint)?[_c('router-link',{attrs:{"to":{ name: 'defi-mint', params: { tokenAddress: item.address } }}},[_vm._v(" Mint ")]),_vm._v(" , ")]:_vm._e(),_c('router-link',{attrs:{"to":{ name: 'defi-repay', params: { tokenAddress: item.address } }}},[_vm._v(" Repay ")])],2)]):[(item.canMint)?[_c('router-link',{attrs:{"to":{ name: 'defi-mint', params: { tokenAddress: item.address } }}},[_vm._v(" Mint ")]),_c('br')]:_vm._e(),_c('router-link',{attrs:{"to":{ name: 'defi-repay', params: { tokenAddress: item.address } }}},[_vm._v(" Repay ")])]]}}])}),_c('deposit-or-borrow-token-window',{ref:"win",attrs:{"token":_vm.dbToken,"deposit-route-name":_vm.depositRouteName,"borrow-route-name":_vm.borrowRouteName}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
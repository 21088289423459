var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"assets-list-dt"},[_c('f-data-table',{staticClass:"f-data-table-body-bg-color",class:{ 'f-data-table-body-bg-color': _vm.defiAssetsList },attrs:{"columns":_vm.columns,"items":_vm.items,"first-m-v-column-width":"6","f-card-off":"","caption":"Asset list"},scopedSlots:_vm._u([{key:"column-asset",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[_c('f-crypto-symbol',{attrs:{"token":item}})],1)]):[_c('f-crypto-symbol',{attrs:{"token":item}})]]}},{key:"column-available",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[_c('f-token-value',{attrs:{"value":value,"token":item,"use-placeholder":false,"max-decimals":_vm.MAX_TOKEN_DECIMALS_IN_TABLES,"no-currency":""}})],1)]):[_c('f-token-value',{attrs:{"value":value,"token":item,"use-placeholder":false,"max-decimals":_vm.MAX_TOKEN_DECIMALS_IN_TABLES,"no-currency":""}})]]}},{key:"column-balance",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[_c('f-token-value',{attrs:{"value":value,"token":item,"use-placeholder":false,"max-decimals":_vm.MAX_TOKEN_DECIMALS_IN_TABLES,"no-currency":""}})],1)]):[_c('f-token-value',{attrs:{"value":value,"token":item,"use-placeholder":false,"max-decimals":_vm.MAX_TOKEN_DECIMALS_IN_TABLES,"no-currency":""}})]]}},{key:"column-borrowed",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[_c('f-token-value',{attrs:{"value":value,"token":item,"use-placeholder":false,"max-decimals":_vm.MAX_TOKEN_DECIMALS_IN_TABLES,"no-currency":""}})],1)]):[_c('f-token-value',{attrs:{"value":value,"token":item,"use-placeholder":false,"max-decimals":_vm.MAX_TOKEN_DECIMALS_IN_TABLES,"no-currency":""}})]]}},{key:"column-totalSupply",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[_c('f-token-value',{attrs:{"value":value,"token":item,"use-placeholder":false,"decimals":0,"no-currency":""}})],1)]):[_c('f-token-value',{attrs:{"value":value,"token":item,"use-placeholder":false,"decimals":0,"no-currency":""}})]]}},{key:"column-actions",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[(_vm.usedAsCollateral(item))?[_c('router-link',{attrs:{"to":{ name: 'defi-lock', params: { tokenAddress: item.address } }}},[_vm._v(" Lock ")]),(item._collateral > 0)?[_vm._v(" , "),_c('router-link',{attrs:{"to":{ name: 'defi-unlock', params: { tokenAddress: item.address } }}},[_vm._v(" Unlock ")])]:_vm._e(),(item.symbol === 'WNEC')?[_vm._v(" , "),_c('router-link',{attrs:{"to":{ name: 'defi-ftrade' }}},[_vm._v("Swap")])]:_vm._e()]:_vm._e(),(item.canMint)?_c('router-link',{attrs:{"to":{ name: 'defi-mint', params: { tokenAddress: item.address } }}},[_vm._v(" Mint ")]):_vm._e(),(item._debt > 0)?[_vm._v(" , "),_c('router-link',{attrs:{"to":{ name: 'defi-repay', params: { tokenAddress: item.address } }}},[_vm._v(" Repay ")])]:_vm._e()],2)]):[(_vm.usedAsCollateral(item))?[_c('router-link',{attrs:{"to":{ name: 'defi-lock', params: { tokenAddress: item.address } }}},[_vm._v(" Lock ")]),(item._collateral > 0)?[_c('br'),_c('router-link',{attrs:{"to":{ name: 'defi-unlock', params: { tokenAddress: item.address } }}},[_vm._v(" Unlock ")])]:_vm._e(),(item.symbol === 'WNEC')?[_c('br'),_c('router-link',{attrs:{"to":{ name: 'defi-ftrade' }}},[_vm._v("Swap")])]:_vm._e()]:_vm._e(),(item.canMint)?_c('router-link',{attrs:{"to":{ name: 'defi-mint', params: { tokenAddress: item.address } }}},[_vm._v(" Mint ")]):_vm._e(),(item._debt > 0)?[_c('br'),_c('router-link',{attrs:{"to":{ name: 'defi-repay', params: { tokenAddress: item.address } }}},[_vm._v(" Repay ")])]:_vm._e()]]}},{key:"column-actions-account",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[(item.symbol !== 'SNEC')?_c('router-link',{staticClass:"action",attrs:{"to":{ name: 'account-send-erc20', params: { token: Object.assign({}, item) } },"title":"Send"}},[_vm._v(" Send ")]):_vm._e()],1)]):[(item.symbol !== 'SNEC')?_c('router-link',{staticClass:"action",attrs:{"to":{ name: 'account-send-erc20', params: { token: Object.assign({}, item) } },"title":"Send"}},[_vm._v(" Send ")]):_vm._e()]]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
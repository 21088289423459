<template>
    <div class="settingslinks">
        <center><f-card class="f-card-double-padding">
            <div class="small-container">
                <p>
                    <a href="https://explorer.ncogchain.earth/" rel="noopener" target="_blank">
                        NCOGEarthChain Explorer
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                        <path d="M9.14194 18.4342C8.88753 18.1798 8.6486 17.9011 8.43289 17.6068C8.07339 17.1135 8.1818 16.421 8.67625 16.0615C9.1696 15.702 9.86094 15.8104 10.2216 16.3038C10.3642 16.5007 10.5246 16.6898 10.7049 16.869C11.5932 17.7573 12.7735 18.2462 14.0289 18.2462C15.2844 18.2462 16.4658 17.7573 17.353 16.869L23.4368 10.7851C25.2697 8.95224 25.2697 5.96894 23.4368 4.13603C21.6039 2.30313 18.6206 2.30313 16.7877 4.13603L15.6174 5.30635C15.1849 5.73885 14.4858 5.73885 14.0533 5.30635C13.6208 4.87384 13.6208 4.17475 14.0533 3.74224L15.2236 2.57193C17.9193 -0.124886 22.3052 -0.124886 25.0009 2.57193C27.6966 5.26763 27.6966 9.65355 25.0009 12.3493L18.9171 18.4331C17.6118 19.7395 15.8751 20.4585 14.0289 20.4585C12.1828 20.4585 10.4461 19.7395 9.14194 18.4342ZM7.392 27.0954C9.23928 27.0954 10.9748 26.3764 12.2801 25.0701L13.4504 23.8997C13.8829 23.4683 13.8829 22.7682 13.4504 22.3356C13.019 21.9031 12.3188 21.9042 11.8863 22.3356L10.7149 23.506C9.82665 24.3942 8.64638 24.8831 7.39089 24.8831C6.13541 24.8831 4.95514 24.3942 4.06689 23.506C3.17865 22.6177 2.68972 21.4374 2.68972 20.182C2.68972 18.9265 3.17865 17.7451 4.06689 16.8579L10.1508 10.7741C11.039 9.88584 12.2193 9.39692 13.4748 9.39692C14.7302 9.39692 15.9116 9.88584 16.7988 10.7741C16.9757 10.9522 17.1372 11.1413 17.281 11.3382C17.6394 11.8327 18.3308 11.9433 18.8264 11.5827C19.3208 11.2232 19.4303 10.5318 19.0708 10.0374C18.8606 9.74757 18.6228 9.46992 18.364 9.21108C17.0576 7.9036 15.3209 7.1846 13.4748 7.1846C11.6286 7.1846 9.89192 7.9036 8.58665 9.20998L2.50389 15.2938C1.19752 16.5991 0.478516 18.3358 0.478516 20.182C0.478516 22.0281 1.19752 23.7648 2.50389 25.0701C3.80916 26.3764 5.54472 27.0954 7.392 27.0954Z" fill="#31BC3C"></path>
                                    </svg>
                    </a>
                </p>

                <social-media-links />
            </div>
        </f-card>
    </center>
    </div>
</template>

<script>
import SocialMediaLinks from '@/components/SocialMediaLinks/SocialMediaLinks.vue';
import FCard from '@/components/core/FCard/FCard.vue';

export default {
    name: 'SettingsLinks',

    components: { FCard, SocialMediaLinks },
};
</script>
